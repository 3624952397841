<template>
  <div class="promo-card-container">
    <v-card flat class="promo-card edit-panel">
      <div class="border-bottom hb-text-night-light py-2 px-2 pl-4">
        Choose a promotion plan you want to assign to the selected space group
      </div>
      <hb-form class="promo-form" label="In-Store & Online Promotion" full>
        <hb-radio-group class="px-0" row v-model="instore_promotion.assignType">
          <HbRadio label="Single Promotion" value="0" />
          <HbRadio v-if="propertyPromotionSettings.automation_enabled_by_admin" label="Promotion Plan" value="1" />
          <HbRadio label="No Promotion" value="2" />
        </hb-radio-group>
        <HbSelect
          v-if="instore_promotion.assignType === '0'"
          v-model="instore_promotion.selectedPromo"
          :items="promotions"
          :error="instore_promotion.error.promotion"
        />
        <HbSelect
          v-if="instore_promotion.assignType === '1'"
          v-model="instore_promotion.selectedPlan"
          :items="plans"
          :error="instore_promotion.error.plan"
        />
      </hb-form>

      <hb-form class="promo-form" label="Online Promotion" full>
        <hb-radio-group row v-model="online_promotion.assignType">
          <HbRadio label="Single Promotion" value="0" />
          <HbRadio v-if="propertyPromotionSettings.automation_enabled_by_admin" label="Promotion Plan" value="1" />
          <HbRadio label="No Promotion" value="2" />
        </hb-radio-group>
        <HbSelect
          v-if="online_promotion.assignType === '0'"
          v-model="online_promotion.selectedPromo"
          :items="promotions"
          :error="online_promotion.error.promotion"
        />
        <HbSelect
          v-if="online_promotion.assignType === '1'"
          v-model="online_promotion.selectedPlan"
          :items="plans"
          :error="online_promotion.error.plan"
        />
      </hb-form>
    </v-card>
    <hb-bottom-action-bar @close="_cancel()" class="bottom-bar-height">
      <template v-slot:right-actions>
        <hb-btn :loading="loading" color="primary" @click="applyPromotion()">
          Save
        </hb-btn>
      </template>
    </hb-bottom-action-bar>
    <hb-modal
      v-model="selectSpace"
      size="medium"
      title="Select space groups in order to update."
      confirmation
      @close="selectSpace = false"
      :footer-off="true"
    >
      <template v-slot:content>
        <div class="pa-4">
          Select any number of space groups that you want to update.
        </div>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MultilinePromoViewer from "../../revenue_management/promotion_management/MultilinePromoViewer.vue";
import api from "@/assets/api.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { EventBus } from "../../../EventBus";

export default {
  name: "BulkAssignPromo",
  components: {
    MultilinePromoViewer,
  },
  mixins: [notificationMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      instore_promotion: {
        selectedPromo: "",
        selectedPlan: "",
        assignType: "",
        error: {
          plan: false,
          promotion: false,
        },
      },

      online_promotion: {
        selectedPromo: "",
        selectedPlan: "",
        assignType: "",
        error: {
          plan: false,
          promotion: false,
        },
      },
      selectSpace: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      promoPlans: "revManStore/promotionPlans",
      activePromotions: "revManStore/getActivePromotions",
      selectedProperty: "revManStore/getSelectedProperty",
      propertyPromotionSettings: "revManStore/getPropertyPromotionSettings",
    }),

    promotions() {
      return this.activePromotions.filter((promo) => promo.label === "promotion")
      .map((promo) => promo.name);
    },

    plans() {
      return this.promoPlans.map((plan) => plan.name);
    },

    isValidAssignPromo() {
      return (
        !!this.online_promotion.assignType ||
        !!this.instore_promotion.assignType
      );
    },
  },

  methods: {
    getPlanId(planName) {
      return this.promoPlans?.find((promo) => promo.name === planName)?.id;
    },

    getPromoId(promoName) {
      return this.activePromotions?.find((promo) => promo.name === promoName)
        ?.id;
    },

    _cancel() {
      Object.keys(this.online_promotion).forEach((key) => {
        this.online_promotion[key] = "";
      });

      Object.keys(this.instore_promotion).forEach((key) => {
        this.instore_promotion[key] = "";
      });
    },

    validateFields() {
      const error = [];

      const validatePromotion = (channelType, errorType) => {
        if (channelType.assignType === "1" && !channelType.selectedPlan) {
          error.push(`Please select an ${errorType} plan to continue`);
          channelType.error.plan = true;
        } else if (
          channelType.assignType === "0" &&
          !channelType.selectedPromo
        ) {
          error.push(`Please select an ${errorType} promotion to continue`);
          channelType.error.promotion = true;
        }
      };

      if (!this.isValidAssignPromo) {
        error.push(
          "Select atleast one of instore promotion or online promotion to continue. "
        );
      }

      validatePromotion(this.instore_promotion, "instore");

      validatePromotion(this.online_promotion, "online");

      if (error.length) {
        this.showMessageNotification({
          description:
            "There are errors in your form, correct them before continuing.",
          list: error,
        });
      }

      return !error.length;
    },

    prepareData() {
      const promoData = {
        unit_group_ids: this.items?.map((item) => item.spacegroup_id) || [],
        promotions: [],
      };

      const createChannelData = (assignType, selectedData, channelType) => {
        const channelData = {
          channel_type: channelType,
          data:
            assignType === "2"
              ? null
              : {
                  id:
                    assignType === "1"
                      ? this.getPlanId(selectedData) ?? ""
                      : this.getPromoId(selectedData) ?? "",
                  type: assignType === "1" ? "plan" : "promotion",
                },
        };
        return channelData;
      };

      const instoreAssignType = this.instore_promotion?.assignType;
      const onlineAssignType = this.online_promotion?.assignType;

      if (instoreAssignType) {
        promoData.promotions.push(
          createChannelData(
            instoreAssignType,
            this.instore_promotion.selectedPlan ||
              this.instore_promotion.selectedPromo,
            "instore_online"
          )
        );
      }

      if (onlineAssignType) {
        promoData.promotions.push(
          createChannelData(
            onlineAssignType,
            this.online_promotion.selectedPlan ||
              this.online_promotion.selectedPromo,
            "online"
          )
        );
      }
      return promoData;
    },

    async applyPromotion() {
      const data = this.prepareData();
      if (data.unit_group_ids?.length === 0) {
        this.selectSpace = true;
        return;
      }

      const valid = this.validateFields();
      if (!valid) return;

      try {
        this.loading = true;
        let url =
          api.getPropertyPromoManagementUrl(this.selectedProperty) +
          "space-groups/assign";
        await api.post(this, url, data);
        this.showMessageNotification({
          type: "success",
          description: "Successufully assigned/removed a promotion/plan",
        });
        EventBus.$emit("promotionManagementEvents", {
          event: "refreshTable",
          data: "",
        });
        this._cancel();
      } catch (err) {
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      } finally {
        this.loading = false;
      }
    },
  },

  watch: {
    "online_promotion.assignType": {
      handler() {
        Object.keys(this.online_promotion.error).forEach((key) => {
          this.online_promotion.error[key] = false;
        });
      },
      immediate: true,
    },
    "instore_promotion.assignType": {
      handler() {
        Object.keys(this.instore_promotion.error).forEach((key) => {
          this.instore_promotion.error[key] = false;
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.promo-card {
  height: 100%;
  overflow-y: auto;
}

.promo-card-container {
  position: absolute;
  top: 0;
  bottom: 64px;
  width: 100%;
  height: calc(100% + 50px);
}

.bottom-bar-height {
  ::v-deep .hb-bab-wrapper {
    height: 63px !important;
  }
}

.promo-form {
  ::v-deep .hb-aviary-form-padding-content.col {
    padding-left: 6px !important;
    padding-right: 2px !important;
  }
}

.hidden {
  display: none !important;
}
</style>
