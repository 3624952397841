<template>
  <div class="panel-title-container hb-cloud-light d-flex align-center">
    <span class="filter-headline font-weight-medium d-flex align-center">
      <slot></slot>
    </span>
    <v-spacer></v-spacer>
    <hb-btn class="mr-2" icon tooltip="Close" @click="$emit('close')" active-state-off>mdi-close</hb-btn>
  </div>
</template>


<script>
    export default {
        name: 'ActionsPanelHeader'
    }
</script>

<style scoped>
.panel-title-container {
  border-bottom: 1px solid #DFE3E8;
  position: sticky;
    top: 0;
    z-index: 999;
}
</style>
