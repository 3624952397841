<template>
  <div style="height: 100%;">
    <div class="send-sms-panel">
      <v-card flat class="edit-panel">
        <div class="px-4" :class="{ 'mt-3': errorHas($options.name) || successHas($options.name)}">
          <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)"
            :message="errorGet($options.name)" status="error"></status>
          <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)"
            :message="successGet($options.name)" status="success"></status>
          <!-- <div class="font-regular hb-text-light">Enter your text message.</div> -->
        </div>
        <v-container class="hb-table" v-show="!showConfirm">
          <v-row class="hb-table-row">
            <v-col class="hb-table-label pl-6 pt-5 d-flex justify-space-between" cols="4">
              Template<hb-tooltip>
                <template v-slot:body>
                  Search for an existing template from the template manager library
                </template>
              </hb-tooltip>
            </v-col>

            <v-col class="hb-table-value">
              <HbAutocomplete v-model="temmplateName" :items="templateNames" v-validate="'required|max:45'"
                item-text='name' item-value='template_id' data-vv-scope="default" data-vv-name="autocomplete-box"
                data-vv-as="Autocomplete Box" :error="errors.has('default.autocomplete-box')"
                placeholder="Select Template" class="mt-2 pl-1" single-line @change="appendtemplate($event)"
                @click:clear="clearTemplate" />
            </v-col>
          </v-row>
          <v-row class="hb-table-row">
            <v-col class="hb-table-value">
              <v-textarea   rows="13" placeholder="Enter Message" dense solo flat hide-details v-model="form.message"></v-textarea>
              <!-- <div v-else style="max-height: 468px; min-height: 400px; overflow: auto; margin-top: 5px;">
              <div v-html="form.message" style="font-size: medium;""></div>
            </div> -->
            </v-col>
          </v-row>

          <v-row class="mr-2 ml-2 pt-2 pb-2">
            <hb-link v-show="showMergeFields !== true" @click="showMergeFields = true">
              Show Available Merge Fields
              <hb-tooltip>

                <template v-slot:body>
                  Use Merge Fields in your message to insert
                  <br />data about your tenants. By using Merge
                  <br />Fields, you can send automated messages
                  <br />to your tenants without having to manually
                  <br />enter their information every time. Merge
                  <br />Fields appear in brackets.
                  <br /><br />
                  In order to use a merge field simply copy
                  <br />and paste the text in brackets and add into
                  <br />your message.
                  <br /><br />
                  Example: Hello [Tenant.FirstName] [Tenant.LastName],
                  <br />you owe [Tenant.TotalPastDue]. Please pay by
                  <br />[Tenant.RentDueDate].
                </template>
              </hb-tooltip>
            </hb-link>

            <hb-link v-show="showMergeFields === true" @click="showMergeFields = false">
              Hide Available Merge Fields
            </hb-link>
            <merge-fields v-if="showMergeFields === true" />
          </v-row>

        </v-container>

        <v-container class="hb-table" v-show="showConfirm">
          <v-row class="hb-table-row">
            <v-col class="hb-table-label pl-6 pt-4" cols="4">Person</v-col>
            <v-col class="hb-table-value text">Phones</v-col>
          </v-row>
          <v-row class="hb-table-row" v-for="(contact, index) in this.contacts" :key="index">
            <v-col class="hb-table-label pl-6 pt-4" cols="4">{{ contact.first }} {{ contact.last }}</v-col>
            <v-col class="hb-table-value text" v-if="contact.Phone">{{ contact.Phone.phone }}</v-col>
            <v-col class="hb-table-value text danger-color" v-else>
              <v-alert style="font-size: 14px;" dense flat text class="ma-0" type="error">No phones found with sms
                capabilities!</v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <hb-bottom-action-bar v-if="!showConfirm" cancel-off>
        <template v-slot:left-actions>
          <hb-menu>
              <template v-slot:menu-activator>
                <hb-btn icon @click="showFile = true,clearfiles = false" mdi-code="mdi-paperclip" active-state-off tooltip="Upload File"></hb-btn>
              </template>
              <!-- <v-list v-if="toggleList">
                <v-list-item class="pointer-cursor" @click="showFile = true,clearfiles = false">
                  Upload
                </v-list-item>
                <v-list-item class="pointer-cursor" @click="openUploadDocs(0)" disabled>
                  Generate Documents (Non-Signature)
                </v-list-item>
                <v-list-item class="pointer-cursor" @click="openUploadDocs(1)" disabled>
                  Generate Documents (Signature)
                </v-list-item>
              </v-list> -->
            </hb-menu>
        </template>
        <template v-slot:right-actions>
          <hb-btn color="secondary" @click="goBack" class="mr-3">Back</hb-btn>
          <hb-btn color="primary" @click="setEditForm">Next</hb-btn>
        </template>
      </hb-bottom-action-bar>

      <hb-bottom-action-bar v-if="showConfirm" cancel-off>
        <template v-slot:left-actions>

        </template>
        <template v-slot:right-actions>
          <hb-btn color="secondary" @click="showConfirm = false, disable = false" class="mr-3">Back</hb-btn>
          <hb-btn color="primary"  :loading="loading" @click="save">Send Message</hb-btn>
        </template>
      </hb-bottom-action-bar>
    </div>
    <add-file :show="showFile" :onClose='onFileClose' :loading="fileLoading" :getAttachments="getAttachments" :clearData="clearfiles" :allowMultiple="false">
    </add-file>
  </div>
</template>

<script>
import api from '../../../assets/api.js';
import Status from '../Messages.vue';
import Loader from '../../assets/CircleSpinner.vue';
import HbDatePicker from '../../assets/HummingbirdDatepicker';
import { EventBus } from '../../../EventBus.js';
import { mapGetters, mapActions } from 'vuex';
import MergeFields from "../../includes/MergeFields";
import { notificationMixin } from '@/mixins/notificationMixin.js';
import addFile from '../../communication_center/AddFile.vue'

export default {
  name: "BulkSendSMS",
  data() {
    return {
      temmplateName: '',
      showConfirm: false,
      form: {
        subject: '',
        email: '',
        message: ''
      },
      loading: false,
      toggleList: false,
      showFile: false,
      fileLoading: false,
      attachments:[],
      contacts: [],
      showMergeFields: false,
      templateNames: [],
      itemsNew:[]
    }
  },
  mixins: [notificationMixin],
  props: ['items'],
  created() {
    this.getContactDetails();
  },
  components: {
    Status,
    Loader,
    HbDatePicker,
    MergeFields,
    'add-file': addFile,
  },
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
      contactsList: "reportStore/getBulkContactsList"
    }),
  },
  mounted() {
    this.getBaseTemplateData()
  },
  methods: {
    ...mapActions({
      getBulkContacts: 'reportStore/addBulkContacts'
    }),
    async goBack(){
      await this.$emit('back');
      EventBus.$emit('send_email:back',true);
    },
    clearTemplate() {
      this.form.message = ''
      this.form.subject = ''
    },
    async getBaseTemplateData() {
      const templates = await this.getAllTemplatesWithId([this.facilityList[0].id]); // only one property will select at a time
      const templateFilterd = templates.filter(template => template.base_template_id === null)
      this.templateNames = templateFilterd.filter(template => template.type === 'sms').map(template => ({ name: template.name, template_id: template.template_id }));
    },
    async getAllTemplatesWithId(properties) {
      return await this.getTemplatesById(properties);
    },
    async getTemplatesById(propertyIds) {
      try {
        const propertyIdQueryString = propertyIds.map(id => `property_id=${id}`).join('&');
        const response = await api.get(this, api.TEMPLATE_MANAGER + `templates?${propertyIdQueryString}&get_only_active_templates=true`);
        return response.templates;
      } catch (error) {
        console.error(`Error fetching templates for property IDs ${propertyIds.join(',')}:`, error);
        throw error; // Rethrow the error to propagate it up
      }
    },
    async appendtemplate(id) {
      try {
        await api.get(this, api.TEMPLATE_MANAGER + `template/${id}`).then((res) => {
          if (res.template.smsContent) {
            this.form.message = this.form.message? this.form.message + ' ' + res.template.smsContent : res.template.smsContent
          }
          else {
            this.showMessageNotification({ type: 'error', description: "Can't Attach Email Template to SMS Field" });
          }
        })
      } catch (error) {
        throw error;
      }
    },
    smsPhones(phones = []) {
      if (!phones.length) return [];
      return phones.filter(p => p.sms);
    },
    closeWindow() {
      this.resetAction();
    },
    onFileClose() {
      this.showFile = false;
      this.toggleList = false;
    },
    async getAttachments(attachments) {
      try {
        this.fileLoading = true;
        this.attachments = attachments;
        if (this.attachments.length) {
          let fileUploadResponse = await this.upload(this.items);
          console.log(fileUploadResponse, "fileUploadResponse");

          let res = await api.get(this, api.SEND_SHORTEN_LINK + `?upload_id=${fileUploadResponse.uploads.id}&contact_id=${this.contacts[0].id}`);
          this.form.message = this.form.message ? this.form.message + ' ' + res.short_url : res.short_url;
          this.fileLoading = false;
          this.attachments= [],
          this.onFileClose();
          this.clearfiles = true
        }
      } catch (error) {
        console.error("Error in getAttachments:", error);
        this.onFileClose();
        this.attachments= [],
        this.fileLoading = false;
        this.clearfiles = true
        // You can add any additional error handling logic here, such as showing an error message
      }
    },
    async upload(contacts) {
      let body = {
        document_type: this.type,
        document_type_id: this.document_type_id,
        contacts: contacts
      }
      let response = await api.postFile(this, api.CONTACTS + 'uploads', body, this.attachments);

      return response

    },
    async getContactDetails() {

      let items = this.items.map(item => {
        return {
          id: item.tenant_id || item.contact_id || item.lead_id,
          lease_id: item.lease_id,
          national_account_id: item?.na_id
        }
      });
      this.itemsNew = [...(this.contactsList.length ? this.contactsList : items)];
      let contacts_data = this.contactsList.filter(data => data.id);

      let response = await api.post(this, api.CONTACTS + 'bulk-config', { contact_ids: contacts_data.length ? contacts_data : items.filter(i => i.id) });
      this.contacts = response.contacts.map(contact => {

        let c = {
          id: contact.id,
          first: contact.first,
          last: contact.last,
        }
        c.Phone = contact.Phones.find(p => p.sms)
        // let data = this.contactsList.length? this.contactsList : items;
        // let item = data.find(item => {
        //   if (c.id === item.id) {
        //     return item.lease_id
        //   }
        // })
        // console.log(item,"item")
        // if(item?.national_account_id){
        //   c.id = item.id
        //   c.lease_id = item.lease_id
        // }else{
        //   c.lease_id = item.lease_id;
        // }

        return c
      });

      
    },
    setEditForm() {

      if (!this.form.message) {
        this.errorSet(this.$options.name, "Please enter your message");
        return;
      };

      this.showConfirm = true;
    },
    async save() {
      try {
        this.loading = true
        let form = {
          message: this.form.message,
          contacts: this.itemsNew
        };
        let response = await api.post(this, api.CONTACTS + 'bulk-send-sms', form);
        if (!response){
          this.loading= false; 
          return ;
        }
        this.successSet(this.$options.name, "Messages Sent");
        this.loading= false; 
        EventBus.$emit('unit_edited');
        this.getBulkContacts([]);
      } catch (error) {
        this.loading= false
        this.errorSet(this.$options.name, "Failed to send messages. Please try again.");
        console.error("Error sending SMS:", error);
      }
    }
  }
}

</script>
<style scoped>
.strike {
  text-decoration: line-through;
  color: #a0a0a0;
}

.enhanced {
  font-weight: 500;
}

.primary-color {
  color: #00727A;

}

.panel-footer {
  border-top: none !important;
  z-index: 5;
  width: 100%;
  bottom: 0;
  position: static !important;
}
</style>
<style lang="scss" scoped>
.send-sms-panel {
  display: flex;
  flex-direction: column;
  height: 100%;

  .edit-panel {
    flex: 1;
    overflow-y: auto;
  }
}
</style>
