<template>
  <div class="editor-container">
    <v-card flat style="height: 100%; overflow: scroll;">
      <div>
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
        <p class="font-regular mt-2 px-5" >Save this report to view it later. Saved reports are displayed in the Reports section.</p>
      </div>
      <v-container class="hb-table" >

        <v-row class="hb-table-row">
          <v-col class="hb-table-label pl-6 pt-5" cols="4">
            Save Report for
          </v-col>
          <v-col class="hb-table-value">
            <v-select
                multiple
                dense
                solo
                flat
                :items="admin_list"
                item-text="name"
                item-value="id"
                v-model.lazy="report.share"
                background-color="white"
                placeholder="Choose Someone"
                v-validate="'required'"
                name='assignee'
                data-vv-scope="report"
                :hide-details="!errors.has('report.assignee')"
                :error-messages="errors.has('report.assignee') ? errors.collect('report.assignee') : null"
              >
            </v-select>
          </v-col>
        </v-row>

        <!-- <v-row class="hb-table-row" v-if="report.id">
          <v-col class="hb-table-label pl-6 pt-5" cols="4">
            Save
          </v-col>
          <v-col class="hb-table-value">
            <v-select
              dense
              hide-details
              solo
              flat
              :items="save_types"
              v-model.lazy="save_type"
              background-color="white"
              placeholder=""
            >
            </v-select>
          </v-col>
        </v-row> -->


        <v-row class="hb-table-row">
          <v-col class="hb-table-label pl-6 pt-5" cols="4">
            Name
          </v-col>
          <v-col class="hb-table-value">
            <v-text-field
              dense
              solo
              flat
              v-model.lazy="report.name"
              background-color="white"
              placeholder="Enter Name"
              name='name'
              v-validate="'required'"
              data-vv-scope="report"
              :hide-details="!errors.has('report.name')"
              :error-messages="errors.has('report.name') ? errors.collect('report.name') : null"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="hb-table-row">
          <v-col class="hb-table-label pl-6 pt-5" cols="4">
            Report Description
          </v-col>
          <v-col class="hb-table-value">
            <v-textarea
              dense
              solo
              flat
              v-model.lazy="report.description"
              background-color="white"
              placeholder="Enter Description"
              name='description'
              v-validate="'required'"
              data-vv-scope="report"
              :hide-details="!errors.has('report.description')"
              :error-messages="errors.has('report.description') ? errors.collect('report.description') : null"
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="hb-table-row" v-if="show_default_report_option" v-show="report.id || report.share.find(r => r === getUserData.id)">
          <v-col class="hb-table-label pl-6 pt-5" cols="4">
            &nbsp;
          </v-col>
          <v-col class="hb-table-value">
            <v-checkbox
              class="hb-table-checkbox"
              dense
              hide-details
              label="Make my default report"
              v-model.lazy="report.is_default"
              background-color="white"
              outlined>
            </v-checkbox>
          </v-col>
        </v-row>

      </v-container>
    </v-card>

    <v-toolbar flat class="panel-footer">
      <v-spacer></v-spacer>
      <hb-link @click="$emit('close')" class="mr-3">
        Cancel
      </hb-link>

      <hb-btn color="primary" @click="saveReport">
        Save Report
      </hb-btn>
    </v-toolbar>


<!--    <v-row>-->
<!--      <v-col>-->
<!--        <v-btn text color="primary" @click="$emit('close')" >Close</v-btn>-->
<!--        <v-btn depressed color="primary" @click="saveReport" class="float-right">Save Report</v-btn>-->
<!--      </v-col>-->
<!--    </v-row>-->
  </div>

</template>

<script type="text/babel">

    import Status from '../Messages.vue';

    import api from '../../../assets/api.js';
    import { tableFilters } from '../../../mixins/urlParser.js';
    import { mapGetters } from 'vuex';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
    export default {
        name: "SaveReport",
        data() {
            return {
                save_types: ['Update Report', "Save As New"],
                set_groups:[],
                admins: [],
                newGroup:{},
                form :{},
                form_loaded: false,
                source_data:{},
                slider_configs:{},
                save_type:'',
                all_admins: [],

                report:{
                    id: '',
                    name: '',
                    description:'',
                    filters: {},
                    path: '',
                    template: '',
                    is_default: false,
                    is_everyone: false,
                    share: []
                },

            }
        },
        components:{
            Status,

        },
        computed: {
          ...mapGetters({
            getUserData:'authenticationStore/getUserData',
            report_by_id: 'reportStore/get_report_by_id',
            view_by_id: 'reportStore/get_view_by_id',
            geDynamicRunParam: 'reportStore/geDynamicRunParam',
          }),

            get_report(){
                return this.report_by_id({
                    report_id: this.selected,
                    report_template: this.template
                });
            },
            admin_list(){

              let admins = this.admins.filter(a => a.contact_id !== this.getUserData.id).map(a => {
                  return {
                      id: a.contact_id,
                      name: a.first + ' ' + a.last
                  }
              });

              admins.unshift({
                  id: -1,
                  name: 'Everyone'
              })
              admins.unshift({
                  id: this.getUserData.id,
                  name: 'Me'
              });

              return admins.sort((a, b) => a.name.localeCompare(b.name));


            }
        },
        mounted(){
        },

        created(){
            // get report from store based on ID

            if(this.selected) {
                this.report = JSON.parse(JSON.stringify(this.get_report));

                this.report.share = [this.getUserData.id];
                this.save_type = 'Update Report';
            }
            if(!this.report.id){
                this.save_type = 'Save As New';
                this.report.filters = JSON.parse(JSON.stringify(this.config));
                this.report.template = this.template;
                this.report.share.push(this.getUserData.id);
            }

            this.getAdmins();

        },
        destroyed(){

        },
        filters:{
        },
        props: ['selected','config','template','show_default_report_option','contact_id'],
        methods:{
            async getAdmins(){
                let r = await api.get(this, api.ADMIN);
                this.admins = r.admins;
            },
            async saveReport(){
                let report_validation_status = await this.$validator.validateAll('report')

                if(!report_validation_status) {
                  this.successClear(this.$options.name)
                  this.showMessageNotification({description: 'There are errors on your form. Please correct them before continuing.' });
                  return;
                }
                
                if(this.report['name'].includes('.')) {
                  this.successClear(this.$options.name)
                  this.showMessageNotification({description: 'You cannot save a report with dots(.) in the name ' });
                  return;
                }

                // To Ensure there are no Pagination Parameter saved in filters for Custom Reports
                this.report.filters.limit = 0;
                let result = '';

                let report = JSON.parse(JSON.stringify(this.report));

               // if(!report.id || this.save_type === 'Save As New'){
                    report.id = null;
                //}
                if(report.template === 'national_account_unit_list'){
                  report.filters.search.contact_id = this.contact_id
                }
                delete report["company_id"];
                delete report["contact_id"];
                delete report["download_xls"];
                delete report["end_date"];
                delete report["pinned"];
                delete report["sort"];
                delete report["template_type"];
                delete report["type"];

                if(this.report.share.find(r => r === -1)) {
                    report.share = this.admins.map(a => a.contact_id);
                    report.is_everyone = true;
                }

                let reportPeriodFromStore = this.geDynamicRunParam?.timePeriodConfig?.report_period;
                let reportPeriod = report.filters?.search?.report_period;

                if (reportPeriod) {
                  report.filters.search.report_period = reportPeriodFromStore;
                }
                report = await this.$store.dispatch('reportStore/saveView', report);

                this.errorClear(this.$options.name)
                this.showMessageNotification({ type: 'success', description: 'Report Saved' });
                this.$emit('saved', report);


                // this.filters = this.config;
                // report.filters = this.filters;
                // this.report.filters = this.filters;



              //   if(!report.id || this.save_type === 'Save As New'){
              //       report.id = null;
              //   }
              //
              //  if(this.report.share.find(r => r === -1)) {
              //      report.share = this.admins.map(a => a.contact_id);
              //  }
              //
              //
              // if(report.id){
              //   result = await api.put(this, api.REPORTS + report.id, report);
              //   this.successSet(this.$options.name, "Report Saved");
              //   this.$emit('edited', report)
              // } else {
              //   result = await api.post(this, api.REPORTS, report);
              //   this.report.id = result.report.id;
              //   report.id = result.report.id;
              //   this.successSet(this.$options.name, "Report Saved");
              //   this.$emit('saved', report);
              // }
            },
            clearReport(){
                this.report.name =  '';
                this.report.description =  '';

            }
        },
        watch:{
            config(){},
            "filters": {
                handler: function (filters) {
                    this.report.filters = filters;

                },
                deep: true
            },
            selected(){
                if(this.selected) {
                    this.report = JSON.parse(JSON.stringify(this.get_report));

                    this.report.share = [this.getUserData.id];
                    this.save_type = 'Update Report';
                }
                if(!this.report.id){
                    this.save_type = 'Save As New';
                    this.report.filters = JSON.parse(JSON.stringify(this.config));
                    this.report.template = this.template;
                    this.report.share.push(this.getUserData.id);
                }
            },
            save_type(val){
                if(val === 'Save As New'){
                    this.report.name = '';
                    this.report.description = '';
                } else {
                    this.report = JSON.parse(JSON.stringify(this.get_report));
                }
            }
        },
        mixins: [tableFilters, notificationMixin]




    }

</script>

<style scoped>

  .form-column-selector-section-container{
    display: flex;
    overflow-x: scroll;

  }
  .form-column-selector-section{
    padding: 10px;
    border-right: 1px solid #e2e2e2;
    flex: 1 0 200px;
  }

  .form-column-selector-section:last-child{
    border: none;
  }
  .main-section-heading label{
    margin-bottom: 3px;
  }


  .lined-padded{
    width: 300px;
    padding: 12px 8px;
    border: 1px solid #e2e2e2;
    box-shadow: 1px 1px 3px 0px #dee5e7;
    margin: 5px 0;
  }

</style>
